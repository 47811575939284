import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';

import { useEditEstate } from '@/features/estates/api/estates';
import { EstateForm } from '@/features/estates/components/estate-form';
import { type EstateSchemaType, getCreateEstateSchema } from '@/features/estates/schemas/estate';
import { Estate, getEstateQueryOptions } from '@/features/estates-property/api/estate';

import { ModalBase } from './modals-base';

export interface EditEstateModalProps {
  estate: Estate;
}

export const EditEstateModal: React.FC<EditEstateModalProps> = ({ estate }) => {
  const schema = getCreateEstateSchema();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync } = useEditEstate({
    mutationConfig: {
      onSuccess: ([estate]) => {
        toast.success(t('update-estate-success'));
        queryClient.invalidateQueries({ queryKey: getEstateQueryOptions(estate?.id).queryKey });
      },
      onError: (err) => {
        toast.error(err.message);
      },
    },
  });

  const methods = useForm<EstateSchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {
      estateData: {
        name: '',
        propertyType: '',
        marketType: '',
      },
      addressData: {
        address: '',
        postalCode: '',
        city: '',
        countryId: '',
      },
    },
  });

  const { reset } = methods;

  useEffect(() => {
    reset((prev) => ({
      ...prev,
      estateData: {
        ...prev.estateData,
        name: estate.name || '',
        marketType: estate?.estateDetails?.marketType || '',
        propertyType: estate?.estateDetails?.propertyType || '',
      },
      addressData: {
        ...prev.addressData,
        address: estate.address?.address || '',
        postalCode: estate.address?.postalCode || '',
        city: estate.address?.city || '',
        countryId: estate.address?.countryId || '',
        ...(estate.address?.lat && { lat: Number(estate.address?.lat) }),
        ...(estate.address?.long && { long: Number(estate.address?.long) }),
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estate]);

  const onSubmit = async (args: EstateSchemaType) => {
    await mutateAsync({
      ...args,
      id: estate.id,
    });
  };

  return (
    <ModalBase>
      <FormProvider {...methods}>
        <EstateForm label={t('edit-estate')} onSubmit={onSubmit} />
      </FormProvider>
    </ModalBase>
  );
};
