import { useQuery } from '@tanstack/react-query';

import { SelectOption } from '@/components/ui/select';
import { i18n } from '@/lib/i18n';
import { api, extractData } from '@/lib/treaty';

type Locale = 'de' | 'en';

export type EstatesOptions = Record<
  keyof Awaited<ReturnType<typeof getFiltersOptions>>,
  SelectOption[]
>;

export const getFiltersOptions = () => {
  return extractData(api.estates['form-options'].get());
};

export const useEstateOptions = () =>
  useQuery({
    queryKey: ['form-options'],
    queryFn: getFiltersOptions,
    select: (data): EstatesOptions => {
      const locale = i18n.language;
      return Object.entries(data).reduce<EstatesOptions>((acc, [key, value]) => {
        return {
          ...acc,
          [key]: Object.values(value).reduce<SelectOption[]>((acc, { value, translations }) => {
            return [...acc, { id: value, title: translations[locale as Locale] }];
          }, []),
        };
      }, {} as EstatesOptions);
    },
  });
