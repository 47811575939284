import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://0e01ef06ff7770d7df39c2fad9b4de74@o4507968654540800.ingest.de.sentry.io/4507968657227856',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  tracePropagationTargets: [
    /^http:\/\/localhost(:\d+)?(\/.*)?\/?$/,
    /^https:\/\/api.realitiq.com(\/.*)?\/?$/,
    /^https:\/\/staging.api.realitiq.com(\/.*)?\/?$/,
  ],
  environment: import.meta.env.MODE,
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
});
